import * as React from "react";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

const ProSpan = styled("span")({
	display: "inline-block",
	height: "1em",
	width: "1em",
	verticalAlign: "middle",
	marginLeft: "0.3em",
	marginBottom: "0.08em",
	backgroundSize: "contain",
	backgroundRepeat: "no-repeat",
	backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

function Label({ componentName, valueType, isProOnly }) {
	const content = (
		<span>
			<strong>{componentName}</strong> for {valueType} editing
		</span>
	);

	if (isProOnly) {
		return (
			<Stack direction='row' spacing={0.5} component='span'>
				<Tooltip title='Included on Pro package'>
					<a
						href='https://mui.com/x/introduction/licensing/#pro-plan'
						aria-label='Included on Pro package'>
						<ProSpan />
					</a>
				</Tooltip>
				{content}
			</Stack>
		);
	}

	return content;
}

export default function CommonlyUsedComponents({ range, onChange, noLabel = false }) {
	function removeLicense() {
		setTimeout(() => {
			const ele = document.getElementsByClassName(
				"MuiDateRangeCalendar-root css-e47596-MuiDateRangeCalendar-root"
			);
			const rem = ele?.[0]?.childNodes?.[0];
			if (rem && rem?.textContent?.includes("Missing license")) {
				ele[0].childNodes[0].style.display = "none";
			}
		}, 0);
	}
	return (
		<div className='date-range'>
			{noLabel ? null : <div>Date Range</div>}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DateRangePicker"]}>
					<DemoItem component='DateRangePicker'>
						<DateRangePicker
							localeText={{
								start: "",
								end: "",
							}}
							format='YYYY-MM-DD'
							value={range}
							onChange={(x) => {
								onChange(x);
							}}
							sx={() => {
								removeLicense();
								return {};
							}}
						/>
					</DemoItem>
				</DemoContainer>
			</LocalizationProvider>
		</div>
	);
}
