import dayjs from "dayjs";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import fileDowload from "react-file-download";
import Load from "../Pages/Common/Loader";
import { useGetResourcesByTypeMutation, useGetResourcesQuery, useLazyGetHistoryQuery } from "../../services/API/appAPI";
import { useEffect, useState } from "react";
import LineChartExtended from "./history/LineChart";
import BarChartExtended from "./history/BarChart";
import DateRange from './Activity/DateRange'


export default History = (props) => {
    const [getHistory, historyObject] = useLazyGetHistoryQuery();
    const [getResourcesByType] = useGetResourcesByTypeMutation();
    const [rooms, setRooms] = useState([])
    const [selectedRoom, setSelectedRoom] = useState('')
    const [selectedRecipeType, setSelectedRecipeType] = useState('light')
    const [chartData, setChartData] = useState([])
    const [historyRange, setHistoryRange] = useState([
		dayjs().subtract(1, "week"),
		dayjs(),
	]);

    const fetchRooms = async () => {
        const { data: rooms = [] } = await getResourcesByType({
            resourceType: "room",
        });
        setRooms(rooms)
    }

    useEffect(() => {
        fetchRooms()
    }, [])

    useEffect(() => {
        const historyObjectAsList = JSON.parse(historyObject?.data || '[]')
        if(historyObjectAsList.length === 0) {
            return;
        }
        const recipedatalist = historyObjectAsList.map(item => item?.recipedata?.optimizerData?.recipe_data?.comparison?.data || [])
        const recipedatalistflat = recipedatalist.flat().sort((a,b) => new Date(a.slot.replace(' GMT', ':00')) - new Date(b.slot.replace(' GMT', ':00')))
        const labels = recipedatalistflat.map(item => item.slot)
        const baseIntensities = recipedatalistflat.map(item => item.base_intensity)
        const modifiedIntensities = recipedatalistflat.map(item => item.modified_intensity)
        const basePrices = recipedatalistflat.map(item => item.base_settlement_price)
        const modifiedPrices = recipedatalistflat.map(item => item.modified_settlement_price)

        const pushTimes = historyObjectAsList.map(item => item?.activityinfo?.time || new Date())
        const savings = historyObjectAsList.map(item => item?.recipedata?.optimizerData?.recipe_data?.comparison?.savings || 0)
        const savingsLabels = recipedatalist.map(item => item.slice(-1)[0].slot)
        const savingsAndLabels = savings.map((val, idx) => ({label: savingsLabels[idx], savings: val, pushTime: pushTimes[idx]})).sort((a,b) => new Date(a.pushTime.replace(' GMT', ':00')) - new Date(b.pushTime.replace(' GMT', ':00')))

        const pricesList = historyObjectAsList.map(item => item?.recipedata?.optimizerData?.recipe_data?.prices || [])
        const prices = pricesList.flat().sort((a,b) => new Date(a.slot.replace(' GMT', ':00')) - new Date(b.slot.replace(' GMT', ':00'))).map(item => item.price)

        setChartData({labels, baseIntensities, modifiedIntensities, basePrices, modifiedPrices, savings: savingsAndLabels.map(s=>s.savings), savingsLabels: savingsAndLabels.map(s => s.label.split('T')[0]), prices})
    }, [historyObject])

    return (
        
        <div>
			<Load open={rooms.length === 0 || historyObject?.isLoading || historyObject?.isFetching} />
            <div>
                <div style={{width: '500px'}}>
                    <FormControl
                        sx={{ m: 1, minWidth: 200, marginBottom: '16px;' }}
                        size='small'
                        className='drop-down'
                        style={{ display: "flex" }}
                        fullWidth>
                        <InputLabel id='room-selector'>
                            Room
                        </InputLabel>
                        <Select
                            labelId='resouce-selector'
                            id='select-small'
                            value={selectedRoom}
                            onChange={(event) => {
                                setSelectedRoom(event.target.value);
                            }}
                            name='roomSelect'
                            label='Room'>
                            {rooms.map((room) => (
                                <MenuItem value={room.name} key={room.id}>
                                    {room.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ m: 1, minWidth: 200 }}
                        size='small'
                        className='drop-down'
                        style={{ display: "flex" }}
                        fullWidth>
                        <InputLabel id='recipe-type-selector'>
                            Recipe Type
                        </InputLabel>
                        <Select
                            labelId='recipe-type-selector'
                            id='select-small'
                            value={selectedRecipeType}
                            name='recipeTypeSelect'
                            label='Recipe Type'>
                            {[{id: 1, value: 'light'}].map((rt) => (
                                <MenuItem value={rt.value} key={rt.id}>
                                    {rt.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '8px' }}>
                    <div>
                        <div style={{ float: 'left' }}>
                            <DateRange
                                range={historyRange}
                                onChange={(range) => setHistoryRange(range)}
                                noLabel
                            />
                        </div>

                        <Button
                            variant='contained'
                            disabled={selectedRoom.length === 0}
                            style={{ margin: '8px', marginTop: '16px', marginLeft: '20px' }}
                            onClick={async () => {
                                const histortData = await getHistory({ room_id: (rooms.find((room) => room.name === selectedRoom) || {})['id'] || '', start_date: historyRange[0].format("YYYY-MM-DD"), end_date: historyRange[1].format("YYYY-MM-DD") }) || []
                            }}>
                            Refresh Data
                        </Button>
                    </div>
                    <br></br><br></br>
                    <div style={{ width: '100%', float: 'left' }}>
                        <Button
                            variant='contained'
                            disabled={chartData.length === 0}
                            style={{ margin: '8px', float: 'left' }}
                            onClick={async () => {
                                const { labels, baseIntensities, modifiedIntensities, basePrices, modifiedPrices, prices } = chartData
                                const data = labels.map((label, idx) => [label, baseIntensities[idx], modifiedIntensities[idx], basePrices[idx], modifiedPrices[idx], prices[idx]])
                                const csvdata = ([['Time Slot', 'Base Intensity', 'Modified Intensity', 'Base Price', 'Modified Price', 'EPEX Price'], ...data]).map(row => row.join(',')).join('\n')
                                fileDowload(csvdata, "recipehistory.csv");
                            }}>
                            Download Recipe/Price data
                        </Button>
                        <Button
                            variant='contained'
                            disabled={chartData.length === 0}
                            style={{ margin: '8px', float: 'left' }}
                            onClick={async () => {
                                const { savings, savingsLabels } = chartData
                                const data = savingsLabels.map((label, idx) => [label, savings[idx]])
                                const csvdata = ([['Day', 'Cost Savings (GBP)'], ...data]).map(row => row.join(',')).join('\n')
                                fileDowload(csvdata, "costsavings.csv");
                            }}>
                            Download Savings data
                        </Button>
                    </div>
                </div>
                <br></br><br></br><br></br><br></br>
            </div>
            {(historyObject?.isLoading || historyObject?.isFetching) ? null : <><br></br><br></br>
            <LineChartExtended chartdata={{labels: chartData.labels, title: "Intensity Comparison Chart", data1: chartData.baseIntensities, data2: chartData.modifiedIntensities, label1: "Base Recipe", label2: "Modified Recipe"}}></LineChartExtended>
            <br></br><br></br><br></br>
            <LineChartExtended chartdata={{labels: chartData.labels, title: "Price Comparison Chart", data1: chartData.basePrices, data2: chartData.modifiedPrices, label1: "Base Recipe Slot Price", label2: "Modified Recipe Slot Price"}}></LineChartExtended>
            <br></br><br></br><br></br>
            <LineChartExtended chartdata={{labels: chartData.labels, title: "EPEX Price Chart", data1: chartData.prices, label1: "EPEX Prices", color1: 'brown'}}></LineChartExtended>
            <br></br><br></br><br></br>
            <BarChartExtended chartdata={{labels: chartData.savingsLabels, title: "Cost Savings", data1: chartData.savings, label1: "Cost Savings"}}></BarChartExtended></>}
		</div>
    )
}