import { useLocation, useParams } from "react-router-dom";

import { Line } from "react-chartjs-2";
import React from "react";

// Try to eleminate importing auto CHart

// https://upmostly.com/tutorials/how-to-use-chart-js-with-react

const LineChartExtended = ({chartdata}) => {
	const chartOptions = {
		responsive: true,
	};

	const data = {
		labels: chartdata.labels,
		datasets: [
			{
				label: chartdata['label1'],
				backgroundColor: chartdata['color1'],
				borderColor: chartdata['color1'],
				data: chartdata['data1'],
				clip: { left: 0, top: false, right: 10, bottom: 0 },
			},
		],
	};

	if(chartdata['data2'] && chartdata['data2'].length > 0) {
		data.datasets.push(
		{
			label: chartdata['label2'],
			backgroundColor: chartdata['color2'],
			borderColor: chartdata['color2'],
			data: chartdata['data2'],
			clip: { left: 0, top: false, right: 10, bottom: 0 },
		})
	}

	return (
		<>
			<div style={{ width: "90%", overflow: 'hidden' }}>
				<Line
					data={data} title={chartdata.title} pointStyle={''}
				/>
			</div>
		</>
		// </div>
	);
};

export default LineChartExtended;
