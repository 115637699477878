import { RouterProvider, createBrowserRouter } from "react-router-dom";

import ActivityLogs from "../MasterPage/Pages/Activity";
import App from "../App";
import AuthorizedRoute from "../MasterPage/Pages/Common/AuthorizedRoute";
import CardsList from "../MasterPage/Pages/DashBoard/CardsList";
import Dashboard from "../MasterPage/Pages/DashBoard";
import EPEXGraph from "../MasterPage/Pages/DashBoard/Charts/EPEX";
import EditRecipe from "../MasterPage/Pages/Recipe/EditRecipe";
import EditRole from "../MasterPage/Pages/Roles/EditRole";
import EditRoom from "../MasterPage/Pages/Rooms/EditRoom";
import EditUser from "../MasterPage/Pages/Users/EditUser";
import ErrorPage from "./Error";
import IntensityGraph from "../MasterPage/Pages/DashBoard/Charts/Intensity";
import LoginForm from "../Login";
import PriceGraph from "../MasterPage/Pages/DashBoard/Charts/Price";
import Profile from "../MasterPage/Pages/Profile";
import RecipeRoomLink from "../MasterPage/Pages/Recipe/RecipeRoomLink";
import Recipes from "../MasterPage/Pages/Recipe";
import RecipesList from "../MasterPage/Pages/Recipe/RecipesList";
import Roles from "../MasterPage/Pages/Roles";
import RolesList from "../MasterPage/Pages/Roles/RolesList";
import Room from "../MasterPage/Pages/Rooms/Room";
import Rooms from "../MasterPage/Pages/Rooms";
import RoomsList from "../MasterPage/Pages/Rooms/RoomsList";
import Settings from "../MasterPage/Pages/Settings";
import User from "../MasterPage/Pages/Users/User";
import Users from "../MasterPage/Pages/Users";
import UsersList from "../MasterPage/Pages/Users/UsersList";
import ViewRole from "../MasterPage/Pages/Roles/RoleView";
import History from "../MasterPage/Pages/history";

// https://reactrouter.com/en/main/start/concepts#defining-routes

const router = createBrowserRouter([
	{ path: "/login", element: <LoginForm /> },
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "",
				element: (
					<AuthorizedRoute>
						<Dashboard />
					</AuthorizedRoute>
				),
				children: [
					{ path: "", element: <CardsList /> },
					{ path: "dashboard", element: <CardsList /> },
					{
						path: "dashboard/:id/intensity-chart",
						element: <IntensityGraph />,
					},
					{ path: "dashboard/:id/epex-chart", element: <EPEXGraph /> },
					{ path: "dashboard/:id/price-chart", element: <PriceGraph /> },
				],
			},
			{
				path: "profile",
				element: <Profile />,
			},
			{
				path: "recipes",
				element: (
					<AuthorizedRoute>
						<Recipes />
					</AuthorizedRoute>
				),
				children: [
					{ path: "", element: <RecipesList /> },
					{ path: "create", element: <EditRecipe /> },
					{ path: ":recipeId", element: <EditRecipe viewOnly /> },
					{ path: "reciperoomlink", element: <RecipeRoomLink /> },
					{ path: ":recipeId/edit", element: <EditRecipe /> },
				],
			},
			{
				path: "rooms",
				element: (
					<AuthorizedRoute>
						<Rooms />
					</AuthorizedRoute>
				),
				children: [
					{ path: "", element: <RoomsList /> },
					{ path: "create", element: <EditRoom key='create' /> },
					{ path: ":roomId", element: <Room /> },
					{ path: ":roomId/edit", element: <EditRoom key='edit' /> },
				],
			},
			{
				path: "users",
				element: (
					<AuthorizedRoute>
						<Users />
					</AuthorizedRoute>
				),
				children: [
					{ path: "", element: <UsersList /> },
					{ path: ":userId", element: <User /> },
					{ path: ":userId/edit", element: <EditUser key='edit' /> },
					{ path: "create", element: <EditUser key='create' /> },
				],
			},
			{
				path: "settings",
				element: (
					<AuthorizedRoute>
						<Settings />
					</AuthorizedRoute>
				),
			},
			{
				path: "roles",
				element: (
					<AuthorizedRoute>
						<Roles />
					</AuthorizedRoute>
				),
				children: [
					{ path: "", element: <RolesList /> },
					{ path: "create", element: <EditRole key={"create"} /> },
					{ path: ":roleId", element: <ViewRole /> },
					{ path: ":roleId/edit", element: <EditRole key={"edit"} /> },
				],
			},
			{
				path: "activity",
				element: (
					<AuthorizedRoute>
						<ActivityLogs />
					</AuthorizedRoute>
				),
			},
			{
				path: "history",
				element: (
					<AuthorizedRoute>
						<History/>
					</AuthorizedRoute>
				),
			}
		],
	},
]);

// const routes = {
// 	dashboard: { path: "/dashboard", title: "Dashboard" },
// 	recipe: { path: "/recipe/:recipeid", title: "Recipe" },
// 	room: { path: "/room/:roomid", title: "Room" },
// 	users: { path: "/users/userid", title: "Users" },
// 	settings: { path: "/settings", title: "Settings" },
// 	roles: { path: "/roles/:roleid", title: "Roles" },
// 	activitylogs: { path: "/activitylogs", title: "Activity Logs" },
// };

// export { routes };

export default function Root() {
	return <RouterProvider router={router} />;
}
