import { useLocation, useParams } from "react-router-dom";

import { Bar } from "react-chartjs-2";
import React from "react";

// Try to eleminate importing auto CHart

// https://upmostly.com/tutorials/how-to-use-chart-js-with-react

const BarChartExtended = ({chartdata}) => {
	const chartOptions = {
		responsive: true,
	};

	const data = {
		labels: chartdata.labels,
		datasets: [
			{
				label: chartdata['label1'],
				backgroundColor: "teal",
				borderColor: "teal",
				data: chartdata['data1'],
				clip: { left: 0, top: false, right: 10, bottom: 0 },
			},
		],
	};

	if(chartdata['data2'] && chartdata['data2'].length > 0) {
		data.datasets.push(
		{
			label: chartdata['label2'],
			backgroundColor: "orange",
			borderColor: "orange",
			data: chartdata['data2'],
			clip: { left: 0, top: false, right: 10, bottom: 0 },
		})
	}

	return (
		<>
			<div style={{ width: "90%", overflow: 'hidden' }}>
				<Bar
					data={data} title={chartdata.title}
				/>
			</div>
		</>
		// </div>
	);
};

export default BarChartExtended;
